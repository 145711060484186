const projects = [
  {
    id: '003',
    titleES: 'App de películas',
    titleEN: 'Movies App',
    descriptionEN: 'I have created a powerful application utilizing React.js and Redux for the front-end, Node.js and Express for the back-end, and PostgreSQL as the database. The application seamlessly integrates with the MovieDB API, providing users with a dynamic and interactive experience.',
    descriptionES:
      'App desarrollada en React.js y Redux, consumiendo la API MovieDB',
    funcionalitiesEN: [
      'Search movies by name',
      'Save of favorite movies in global state (Redux)',
    ],
    funcionalitiesES: [
      'Búsqueda de películas por nombre',
      'Guardado de películas favoritas en estado global (Redux)',
    ],
    tools: ['React.js', 'Redux', 'Css Modules', 'Node.js', 'Express.js', 'PostgreSQL', 'MovieDB API'],
    deploy: '',
    repository: 'https://github.com/maxsolfar/movies-app',
    image: 'https://i.imgur.com/4YeibeS.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Front End',
    creationDate: '05-07-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Full Stack Developer',
    duration: '2 months',
  },
  {
    id: '004',
    titleES: 'Rick & Morty App',
    titleEN: 'Rick & Morty App',
    descriptionEN:
      `I have developed a React.js frontend application that enhances the functionality of the Rick & Morty API. The backend is built with Node.js, Express, and PostgreSQL, enabling seamless integration and enhanced features.`,
    descriptionES:
      'App en React.js, consume y agrega nuevas funcionalidades a la API de Rick & Morty.',
    funcionalitiesEN: [
      'Search bar to search for characters by name',
      'Character detail page',
      'New character creation page',
    ],
    funcionalitiesES: [
      'Search bar para buscar personajes por nombre',
      'Página de detalle del personaje',
      'Página de creación de nuevos personajes',
    ],
    tools: [
      'React.js',
      'Redux',
      'PostgreSQL',
      'Node.js',
      'Express.js'
    ],
    deploy: 'https://rick-and-morty-app-zeta-ten.vercel.app/',
    repository: 'https://github.com/maxsolfar/rick-and-morty-app',
    image: 'https://i.imgur.com/nr6neq9.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Full Stack',
    creationDate: '20-07-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Full Stack Developer',
    duration: '2 months',
  },
  {
    id: '005',
    titleES: 'Game Flix App',
    titleEN: 'Game Flix App',
    descriptionEN:
      'I have created a React.js application that utilizes and extends the functionality of the Rawg.io API. The app incorporates new features and enhancements while leveraging React.js for the frontend development.',
    descriptionES:
      'App en React.js, consume y agrega nuevas funcionalidades a la API (rawg.io)',
    funcionalitiesEN: [
      'Dynamic paging of videogames',
      'Multiple filtering and sorting',
      'Videogame detail page',
      'New videogame creation page',
    ],
    funcionalitiesES: [
      'Paginado dinámico de videojuegos',
      'Filtrado múltiple y ordenamientos',
      'Página de detalle del videojuego',
      'Página de creación de nuevos videojuegos',
    ],
    tools: [
      'React.js',
      'Redux',
      'PostgreSQL',
      'Node.js',
    ],
    deploy: '',
    repository: 'https://github.com/maxsolfar/gameflix-app',
    image: 'https://i.imgur.com/6cmAyv8.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Full Stack',
    creationDate: '20-07-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Full Stack Developer',
    duration: '2 months',
  },
  {
    id: '006',
    titleES: 'CookIt App',
    titleEN: 'CookIt App',
    descriptionEN:
      'I have developed a cooking recipes app using React.js for the frontend, and Laravel and PHP for the backend. The app makes use of the Spoonacular API to provide a diverse collection of recipes and related information.',
    descriptionES:
      'App de recetas de cocina desarrollada en React.js, hace uso de la API Spoonacular',
    funcionalitiesEN: [
      'More than 100 recipes displayed',
      'Dynamic paging of recipes',
      'Multiple filtering and sorting',
      'Recipe detail page',
      'New recipe creation page',
    ],
    funcionalitiesES: [
      'Mas de 100 recitas presentada',
      'Paginado dinámico de recetas',
      'Filtrado múltiple y ordenamientos',
      'Página de detalle de la receta',
      'Página de creación de nuevas recetas',
    ],
    tools: [
      'React.js',
      'Redux',
      'PostgreSQL',
      'Node.js',
    ],
    deploy: 'https://cookit-app.vercel.app/',
    repository: 'https://github.com/maxsolfar/cookit-recipes-app',
    image: 'https://i.imgur.com/6FurWOf.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Full Stack',
    creationDate: '10-08-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Full Stack Developer',
    duration: '2 months',
  },
  {
    id: '007',
    titleES: 'Administrador de Tareas API',
    titleEN: 'Task Manager API',
    descriptionEN:
      'I have developed a RESTful API using Node.js and MongoDB, implementing token-based authentication with JWT (JSON Web Tokens). The API includes token authentication and refresh token functionality, and for documentation purposes, I have utilized Express Validator and Swagger.',
    descriptionES:
      'API REST desarrollada en node.js y mongodb, incluye authentificación por Token y refresh Token.',
    funcionalitiesEN: [
      'Sensitive data hashing with Bcryptjs and validations with Express Validator',
      'Generating tokens to handle authentication with JsonWebTokens.',
      'Storing tokens and authorization data in Cookies and LocalStorage.',
      'API documentation with Swagger UI.',
    ],
    funcionalitiesES: [
      'Hasheo de datos sensibles con Bcryptjs y validaciones con Express Validator.',
      'Generación de tokens para manejar la autenticación con JsonWebTokens.',
      'Almacenamiento de tokens y datos de autorización en Cookies y LocalStorage.',
      'Documentación de la API con Swagger UI.',
    ],
    tools: [
      'Node.js',
      'Express',
      'JWT',
      'Swagger UI',
      'MongoDB',
    ],
    deploy: 'https://task-manager-api-rest-production.up.railway.app/api/doc/',
    repository: 'https://github.com/maxsolfar/task-manager-api-rest',
    image: 'https://i.imgur.com/iJctdw2.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Back End',
    creationDate: '26-08-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Back-End Developer',
    duration: '2 months',
  },
  {
    id: '008',
    titleES: 'Medic App',
    titleEN: 'Medic App',
    descriptionEN:
      `
      I led front-end development for a platform connecting patients with specialized doctors, utilizing React.js, Redux Toolkit, and Chart.js for real-time charts. I designed a responsive layout using Figma and Tailwind CSS, and implemented efficient email data delivery with NodeMailer and SendGrid tools.`,
    descriptionES:
      'Plataforma que conecta a pacientes y médicos de distintas especialidades, agendar citas médicas y agenda online.',
    funcionalitiesEN: [
      'Sensitive data hashing with Bcryptjs and validations with Express Validator',
      'Generating tokens to handle authentication with JsonWebTokens.',
      'Storing tokens and authorization data in Cookies and LocalStorage.',
      'API documentation with Swagger UI.',
    ],
    funcionalitiesES: [
      'Hasheo de datos sensibles con Bcryptjs y validaciones con Express Validator.',
      'Generación de tokens para manejar la autenticación con JsonWebTokens.',
      'Almacenamiento de tokens y datos de autorización en Cookies y LocalStorage.',
      'Documentación de la API con Swagger UI.',
    ],
    tools: [
      'React.js',
      'Tailwind',
      'Node.js',
      'MongoDB',
      'Stripe',
    ],
    deploy: 'https://medic-app-pf.vercel.app/',
    repository: 'https://github.com/maxsolfar/MedicApp',
    image: 'https://i.imgur.com/F5YaJPJ.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Full Stack',
    creationDate: '29-09-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Front-End Developer',
    duration: '2 months',
  },
  {
    id: '009',
    titleES: 'JTracking',
    titleEN: 'JTracking',
    descriptionEN:
      'I built a Job Tracking System application using React.js for the front-end and Node.js with TypeScript for the back-end. The application utilizes React, Context API, and Tailwind CSS to deliver a seamless and visually appealing user experience.',
    descriptionES:
      'Sistema de trackeo de aplicaciones a trabajos con React & Node.js',
    funcionalitiesEN: [
      'Sensitive data hashing with Bcryptjs and validations with Express Validator',
      'Generating tokens to handle authentication with JsonWebTokens.',
      'Storing tokens and authorization data in Cookies and LocalStorage.',
      'API documentation with Swagger UI.',
    ],
    funcionalitiesES: [
      'Hasheo de datos sensibles con Bcryptjs y validaciones con Express Validator.',
      'Generación de tokens para manejar la autenticación con JsonWebTokens.',
      'Almacenamiento de tokens y datos de autorización en Cookies y LocalStorage.',
      'Documentación de la API con Swagger UI.',
    ],
    tools: [
      'React.js',
      'Chakra UI',
      'Context API',
      'Node.js',
    ],
    deploy: 'https://jtracking.vercel.app/',
    repository: 'https://github.com/maxsolfar/jtracking',
    image: 'https://i.imgur.com/TNZEHaD.png',
    gallery: [
      'https://i.imgur.com/2fLYFdv.png',
      'https://www.arenismedico.com/assets/images/staff.jpg',
      'https://www.arenismedico.com/assets/images/staff.jpg',
    ],
    category: 'Full Stack',
    creationDate: '01-12-2022',
    active: true,
    color: '#D4D4FF',
    role: 'Full Stack Developer',
    duration: '2 months',
  },
];

export default projects;
